import { FC, Fragment, useRef, useState } from 'react';

import {
	BookieRatingItem,
	CasinoRatingItem,
	ContentType,
	Entity,
	FaqItem,
	FaqItemMultiLang,
	Post,
	SportId,
} from '@ui-kit/main-api-types';
import { PostsWidget } from '@ui-kit/main-frontend-ui-kit/dist/src/components/Posts-widget';
import { UiButton } from '@ui-kit/main-frontend-ui-kit/dist/src/components/Ui-button';
import { getLocaleLink } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/helpers';
import { PROJECTS } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import dynamic from 'next/dynamic';
import { useLocale, useTranslations } from 'next-intl';
import { InView } from 'react-intersection-observer';

import styles from './MainContentList.module.scss';

import PostCardHOC from '@components/Post-unification/PostCardHOC';
import RBWidgetHOC from '@components/RBWidgetHOC';
import { useUpdateContent } from '@hooks/useUpdateContent';
import { votePoll } from '@store/poll/actions';
import { getContentList } from '@store/tag_unification/actions';
import { useUserStore } from '@store/user';
import { IRbWidgetLinks } from '@utils/bookie';
import { sendAnalytics } from '@utils/helpers';
import { ContentTypeName, LANG } from '@utils/types';

const PollWidget = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/PollWidget').then(
			m => m.PollWidget,
		),
	{
		ssr: true,
	},
);

const NoSSRHOC = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/NoSSRHOC').then(
			m => m.NoSsr,
		),
	{
		ssr: false,
	},
);

const CustomRatingsWidgetHOC = dynamic(
	() => import('@components/CustomRatingsWidgetHOC'),
	{
		ssr: true,
	},
);

const StructuredBodyHOC = dynamic(
	() =>
		import(
			'@ui-kit/main-frontend-ui-kit/dist/src/components/StructuredBodyHOC'
		).then(m => m.StructuredBodyHOC),
	{
		ssr: true,
	},
);

const FAQ = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/FAQ').then(
			m => m.FAQ,
		),
	{
		ssr: true,
	},
);

const BetweenContentBanner = dynamic(
	() => import('@components/Banner-between-content'),
	{
		ssr: false,
	},
);

interface IMainContentList {
	contentList: Entity[];
	isItMainPage?: boolean;
	mainInSection?: boolean;
	popularNotEditorialPosts?: Post[];
	displayPostsWidget?: boolean;
	displayBookieWidget?: boolean;
	sportPath?: string;
	sportType?: SportId;
	displayCRWidget?: boolean;
	customRatings?: BookieRatingItem[] | CasinoRatingItem[];
	isBookie?: boolean;
	showBlog?: boolean;
	rbWidgetLinks?: IRbWidgetLinks;
	faq?: FaqItemMultiLang[] | FaqItem[];
	hasAdditionalPageParams?: boolean;
	seoText?: string[];
	isAutoLoadingTurnedOn?: boolean;
	showLoadMoreButton?: boolean;
	showEditButton?: boolean;
	displayContentBanner?: boolean;
}

export const MainContentList: FC<IMainContentList> = ({
	contentList,
	showEditButton = false,
	isItMainPage,
	mainInSection,
	popularNotEditorialPosts,
	displayPostsWidget = false,
	displayBookieWidget,
	sportPath,
	sportType = SportId.Football,
	displayCRWidget,
	displayContentBanner = false,
	customRatings,
	isBookie,
	showBlog,
	rbWidgetLinks,
	faq = [],
	hasAdditionalPageParams = false,
	isAutoLoadingTurnedOn = false,
	showLoadMoreButton = true,
	seoText,
}) => {
	const locale = useLocale();
	const localeLink = getLocaleLink(locale);
	const [content, setContent] = useState(contentList);
	const currentPage = useRef(1);

	const t = useTranslations();
	const { user, openLoginModal } = useUserStore(state => state);
	const { isPollsLoaded } = useUpdateContent({
		content,
		userStore: user,
		locale,
		setContent,
	});

	const linkToBlog = `${locale}/${sportPath ? `${sportPath}/blogs` : 'blogs'}/`;

	const hasFaqOnCurrentLocale = !!faq?.[0]?.title?.[locale];
	const shouldAddFAQWidget =
		!!faq?.length && hasFaqOnCurrentLocale && !hasAdditionalPageParams;

	const getMoreContent = async () => {
		currentPage.current += 1;

		const contentQueryResult = await getContentList({
			page: currentPage.current,
			locale,
			contentTypes: [ContentType.Poll, ContentType.Blogpost],
			pageSize: 40,
			sport: [sportType],
			main: isItMainPage,
			mainInSection,
		});

		const contentList = contentQueryResult?.contentList;
		setContent(prev => [...prev, ...contentList]);
	};

	const pollRequests = {
		openLoginModal,
		votePoll,
	};

	return (
		<div className={styles['main-content']}>
			<ul className={styles['main-content__list']}>
				{content?.map((el, index) => {
					const contentType = el.__typename;
					const shouldShowPostsWidget =
						index === 3 &&
						displayPostsWidget &&
						!!popularNotEditorialPosts?.length;
					const shouldShowCRWidget = index === 3 && displayCRWidget;
					const shouldDisplayBookieWidget = index === 3 && displayBookieWidget;

					const shouldShowFAQ =
						shouldAddFAQWidget &&
						(index === 18 ||
							(index === content?.length - 1 && content?.length - 1 < 18));
					const shouldShowSeoText =
						!!seoText?.length &&
						!hasAdditionalPageParams &&
						(index === 18 ||
							(index === content?.length - 1 && content?.length - 1 < 18));

					return (
						<Fragment key={el?.id}>
							{contentType === ContentTypeName.CustomPoll && (
								<InView
									as={'div'}
									className={styles['main-content__post-wrapper']}
									triggerOnce
									threshold={0.75}
									onChange={() =>
										sendAnalytics({
											category: 'poll_view',
											name: 'view',
											label: 'poll',
											value: el?.id,
										})
									}
								>
									<PollWidget
										poll={el}
										locale={locale}
										userStore={user}
										pollRequests={pollRequests}
										isLoaded={isPollsLoaded}
										project={PROJECTS.INT}
									/>
								</InView>
							)}
							{contentType === ContentTypeName.Post && (
								<div
									className={`${styles['main-content__post-wrapper']}`}
									data-test={isItMainPage ? 'main-post-item' : null}
								>
									<PostCardHOC
										key={`blog-${el.id}`}
										post={el}
										showBlog={showBlog}
										showEditButton={showEditButton}
									/>
								</div>
							)}
							<NoSSRHOC hideFromBot>
								{displayContentBanner && <BetweenContentBanner />}
							</NoSSRHOC>
							{shouldShowCRWidget && (
								<div className={styles['main-content__cr-widget']}>
									<CustomRatingsWidgetHOC
										ratings={customRatings}
										isVertical={false}
										isBookie={isBookie}
										isInCentralColumn
									/>
								</div>
							)}
							{shouldShowPostsWidget && (
								<PostsWidget
									widgetDataTest="best-post-section"
									headerDataTest="best-post-section-header"
									buttonDataTest="best-post-section-button-all"
									posts={popularNotEditorialPosts}
									sportPath={sportPath}
									localeLang={locale as LANG}
									linkToBlog={linkToBlog}
									style={{
										marginTop: !isItMainPage ? '10px' : '20px',
										marginBottom: isItMainPage ? '20px' : '0',
									}}
									localeLink={localeLink}
								/>
							)}
							{shouldDisplayBookieWidget && (
								<RBWidgetHOC
									rbWidgetLinks={rbWidgetLinks}
									className={styles['main-content__rb-widget']}
								/>
							)}

							{shouldShowSeoText && (
								<div className={styles['main-content__seo-text']}>
									<StructuredBodyHOC seoText={seoText} />
								</div>
							)}
							{shouldShowFAQ && (
								<div className={styles['main-content__faq']}>
									<FAQ
										data={faq}
										locale={locale}
										buttonName={t('watch-all')}
										firstShowLimit={5}
									/>
								</div>
							)}
						</Fragment>
					);
				})}
			</ul>
			{showLoadMoreButton && (
				<UiButton
					onClick={getMoreContent}
					isAutoLoadingTurnedOn={isAutoLoadingTurnedOn}
				>
					{t('translations.load-more')}
				</UiButton>
			)}
		</div>
	);
};
